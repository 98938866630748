import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { ApiGateway } from '../../../api/ApiGateway.service';
import { UnsafeAction } from '../../interfaces';
import { AbsenceRestrictionConflictRequest, AbsenceRestrictionModel } from './absence-restriction.model';

@Injectable()
export class AbsenceRestrictionApi {
  private endpoint = 'absence/restrictions';

  public constructor(private gateway: ApiGateway) {}

  public load(dispatchStart?: UnsafeAction): Observable<AbsenceRestrictionModel[]> {
    return this.gateway.get(this.endpoint, null, dispatchStart);
  }

  public save(data: AbsenceRestrictionModel, dispatchStart?: UnsafeAction): Observable<AbsenceRestrictionModel> {
    return this.gateway.post(this.endpoint, data, null, dispatchStart);
  }

  public update(
    id: number,
    data: AbsenceRestrictionModel,
    dispatchStart?: UnsafeAction
  ): Observable<AbsenceRestrictionModel> {
    return this.gateway.put(`${this.endpoint}/${id}`, data, null, dispatchStart);
  }

  public delete(id: number, dispatchStart?: UnsafeAction): Observable<void> {
    return this.gateway.delete(`${this.endpoint}/${id}`, null, dispatchStart);
  }

  public validateAbsence(data: AbsenceRestrictionConflictRequest) {
    return this.gateway.post('absence_compliance/check', data);
  }
}
