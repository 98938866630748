import { Injectable } from '@angular/core';
import { AbsenceRestrictionApi } from './absence-restriction.api';
import { AppState } from '../../index';
import { Store } from '@ngrx/store';
import { AbsenceRestrictionAction } from './absence-restriction.action';
import { catchError, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AbsenceRestrictionConflictRequest, AbsenceRestrictionModel } from './absence-restriction.model';
import omit from 'lodash-es/omit';

@Injectable()
export class AbsenceRestrictionService {
  public constructor(private api: AbsenceRestrictionApi, private store: Store<AppState>) {}

  public load() {
    return this.api.load(AbsenceRestrictionAction.load()).pipe(
      tap((data) => {
        this.store.dispatch(AbsenceRestrictionAction.loadSuccess(data));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceRestrictionAction.loadFailed(response));
        return throwError(response);
      })
    );
  }

  public save(data: AbsenceRestrictionModel) {
    return this.api.save(data, AbsenceRestrictionAction.add()).pipe(
      tap((response) => {
        this.store.dispatch(AbsenceRestrictionAction.addSuccess(response));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceRestrictionAction.addFailed(response));
        return throwError(response);
      })
    );
  }

  public update(id: number, data: AbsenceRestrictionModel) {
    return this.api.update(id, data, AbsenceRestrictionAction.update()).pipe(
      tap((response) => {
        this.store.dispatch(AbsenceRestrictionAction.updateSuccess({ id, changes: omit(response, ['id']) }));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceRestrictionAction.updateFailed(response));
        return throwError(response);
      })
    );
  }

  public delete(id: number) {
    return this.api.delete(id, AbsenceRestrictionAction.delete()).pipe(
      tap(() => {
        this.store.dispatch(AbsenceRestrictionAction.deleteSuccess(id));
      }),
      catchError((response) => {
        this.store.dispatch(AbsenceRestrictionAction.deleteFailed(response));
        return throwError(response);
      })
    );
  }

  public validateAbsence(data: AbsenceRestrictionConflictRequest) {
    return this.api.validateAbsence(data);
  }
}
