import { UnsafeAction as Action } from '../../interfaces';
import { AbsenceRestrictionModel } from './absence-restriction.model';
import { Update } from '@ngrx/entity';

const ACTION_PREFIX = '[Absence Restriction]';

export const absenceRestrictionActionType = {
  LOAD: `${ACTION_PREFIX} Load`,
  LOAD_SUCCESS: `${ACTION_PREFIX} Load Success`,
  LOAD_FAILED: `${ACTION_PREFIX} Load Failed`,

  ADD: `${ACTION_PREFIX} Add`,
  ADD_SUCCESS: `${ACTION_PREFIX} Add Success`,
  ADD_FAILED: `${ACTION_PREFIX} Add Failed`,

  UPDATE: `${ACTION_PREFIX} Update`,
  UPDATE_SUCCESS: `${ACTION_PREFIX} Update Success`,
  UPDATE_FAILED: `${ACTION_PREFIX} Update Failed`,

  DELETE: `${ACTION_PREFIX} Delete`,
  DELETE_SUCCESS: `${ACTION_PREFIX} Delete Success`,
  DELETE_FAILED: `${ACTION_PREFIX} Delete Failed`,
};

export class AbsenceRestrictionAction {
  public static load(): Action {
    return {
      type: absenceRestrictionActionType.LOAD,
    };
  }

  public static loadSuccess(data: AbsenceRestrictionModel[]): Action {
    return {
      type: absenceRestrictionActionType.LOAD_SUCCESS,
      payload: data,
    };
  }

  public static loadFailed(err): Action {
    return {
      type: absenceRestrictionActionType.LOAD_FAILED,
      payload: err,
    };
  }

  public static add(): Action {
    return {
      type: absenceRestrictionActionType.ADD,
    };
  }

  public static addSuccess(data): Action {
    return {
      type: absenceRestrictionActionType.ADD_SUCCESS,
      payload: data,
    };
  }

  public static addFailed(err): Action {
    return {
      type: absenceRestrictionActionType.ADD_FAILED,
      payload: err,
    };
  }

  public static update(): Action {
    return {
      type: absenceRestrictionActionType.UPDATE,
    };
  }

  public static updateSuccess(data: Update<AbsenceRestrictionModel>): Action {
    return {
      type: absenceRestrictionActionType.UPDATE_SUCCESS,
      payload: data,
    };
  }

  public static updateFailed(err): Action {
    return {
      type: absenceRestrictionActionType.UPDATE_FAILED,
      payload: err,
    };
  }

  public static delete(): Action {
    return {
      type: absenceRestrictionActionType.DELETE,
    };
  }

  public static deleteSuccess(id): Action {
    return {
      type: absenceRestrictionActionType.DELETE_SUCCESS,
      payload: id,
    };
  }

  public static deleteFailed(err): Action {
    return {
      type: absenceRestrictionActionType.DELETE_FAILED,
      payload: { err },
    };
  }
}
